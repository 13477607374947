<template>
  <div class="pageContent">
    <div class="notice-title">{{title}}</div>
    <div class="notice-info">日期：{{createTime}}</div>
    <div class="notice-content">
      <div v-html="content"></div>	  
    </div>
  </div>
</template>

<script>

import {getDetailByUuid} from '@/api/repairs.js';

export default {
  data() {
    return {
      uuid: '',
      content:'',
      createTime:'',
      title:'',
      info: {}
    };
  },

  created() {
    this.getZmNoticeDetail()
  },
  methods: {
    getZmNoticeDetail() {
      getDetailByUuid({uuid: this.$router.currentRoute.query.uuid}).then(res =>{
        this.content = res.data.content
        this.title = res.data.title
        this.createTime = res.data.createTime

      })

    },


  }
};
</script>

<style lang="scss" scoped>
.pageContent {
  padding: 0px 16px;

  .notice-title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
  }

  .notice-info {
    font-size: 13px;
    color: #6e6e6e;
    padding-left: 35px;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #e8e8e8;
  }

  .notice-content {
    //margin-top: 30px;
    padding: 15px 0px;
	::v-deep img{
	     max-width:100%;
	 }
  }
}
</style>
